import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Table,
  Form,
  Container,
  Breadcrumb,
  Spinner, // Import Spinner for loader
} from "react-bootstrap";
import Loader from "../../ui/Layout/Loader";
import { fetchData, patchData } from "../../api/apiService";
import { ToastContainer, toast } from "react-toastify";
import { FiEdit2 } from "react-icons/fi";
import "react-toastify/dist/ReactToastify.css";
import { FaRegCheckCircle } from "react-icons/fa";

const ManualReviewViewComponent = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [manualReviewData, setManualReviewData] = useState(null);
  const [initialManualReviewData, setInitialManualReviewData] = useState(null);
  const [uniqueId, setUniqueId] = useState("");
  const [formValues, setFormValues] = useState({});
  const [rawS3URL, setRawS3URL] = useState("");
  const [editableRows, setEditableRows] = useState({});
  const [isLoading, setIsLoading] = useState(false); // Loading state for API call
  const currentUserId = localStorage.getItem("user_id");
  const currentUserName = localStorage.getItem("user_name");

  useEffect(() => {
    const fetchDocumentData = async () => {
      try {
        const response = await fetchData(`/documents/${id}`);
        const documentData = response;
        setManualReviewData(documentData.manualReviewData.formattedJson);
        setInitialManualReviewData(
          JSON.parse(
            JSON.stringify(documentData.manualReviewData.formattedJson)
          )
        ); // Save initial state
        setUniqueId(
          documentData.manualReviewData.formattedJson.pvp?.id?.key || ""
        ); // Set Unique ID
        setRawS3URL(documentData.rawS3URL);

        setFormValues({
          patientName:
            documentData.manualReviewData.formattedJson.patientInfo?.name
              ?.key || "",
          gender:
            documentData.manualReviewData.formattedJson.patientInfo?.gender
              ?.key || "",
          patientId:
            documentData.manualReviewData.formattedJson.patientInfo?.id?.key ||
            "",
          office:
            documentData.manualReviewData.formattedJson.providerInfo?.practice
              ?.key || "",
          dob:
            documentData.manualReviewData.formattedJson.patientInfo?.dob?.key ||
            "",
          dateOfService:
            documentData.manualReviewData.formattedJson.providerInfo
              ?.dateOfService?.key || "",
          providerName:
            documentData.manualReviewData.formattedJson.providerInfo?.name
              ?.key || "",
          mbi:
            documentData.manualReviewData.formattedJson.patientInfo?.mbi?.key ||
            "",
        });
      } catch (error) {
        console.error("Error fetching document details:", error);
        toast.error("Error fetching document details");
      }
    };

    fetchDocumentData();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setManualReviewData((prevData) => {
      const updatedData = { ...prevData };
      if (!updatedData.patientInfo) updatedData.patientInfo = {};
      if (!updatedData.providerInfo) updatedData.providerInfo = {};

      switch (name) {
        case "patientName":
          updatedData.patientInfo.name = { key: value };
          break;
        case "gender":
          updatedData.patientInfo.gender = { key: value };
          break;
        case "patientId":
          updatedData.patientInfo.id = { key: value };
          break;
        case "dob":
          updatedData.patientInfo.dob = { key: value };
          break;
        case "mbi":
          updatedData.patientInfo.mbi = { key: value };
          break;
        case "providerName":
          updatedData.providerInfo.name = { key: value };
          break;
        case "dateOfService":
          updatedData.providerInfo.dateOfService = { key: value };
          break;
        case "office":
          updatedData.providerInfo.office = { key: value };
          break;
        default:
          break;
      }
      return updatedData;
    });
  };

  const handleRowInputChange = (e, section, index, field) => {
    const { value } = e.target;
    setManualReviewData((prevData) => {
      const updatedData = { ...prevData };
      if (!updatedData[section][index]) {
        updatedData[section][index] = {};
      }
      if (!updatedData[section][index][field]) {
        updatedData[section][index][field] = { key: "" };
      }
      updatedData[section][index][field].key = value;
      return updatedData;
    });
  };

  const handleCheckboxChange = (e, section, index, status) => {
    setManualReviewData((prevData) => {
      const updatedData = { ...prevData };
      if (!updatedData[section][index]) {
        updatedData[section][index] = {};
      }
      if (!updatedData[section][index].status) {
        updatedData[section][index].status = {};
      }
      if (!updatedData[section][index].status[status]) {
        updatedData[section][index].status[status] = { key: false };
      }
      updatedData[section][index].status[status].key = e.target.checked;
      return updatedData;
    });
  };

  const handleApproveClick = async () => {
    setIsLoading(true); // Set loading to true when starting API call
    const formatDate = (date) => {
      const d = new Date(date);
      const dd = String(d.getDate()).padStart(2, "0");
      const mm = String(d.getMonth() + 1).padStart(2, "0");
      const yyyy = d.getFullYear();
      return `${dd}/${mm}/${yyyy}`;
    };

    const payload = {
      reviewedById: currentUserId,
      reviewedByName: currentUserName,
      reviewedAt: formatDate(new Date()),
      patientInfo: {},
      providerInfo: {},
      recaptures: [],
      suspects: [],
      qualityMeasure: [],
    };

    const addAuditData = (section, key, oldValue, newValue) => {
      if (!payload[section]) {
        payload[section] = {};
      }
      payload[section][key] = {
        value: newValue,
        audit: {
          oldValue: oldValue,
          newValue: newValue,
        },
      };
    };

    const initialPatientInfo = initialManualReviewData?.patientInfo || {};
    const currentPatientInfo = manualReviewData?.patientInfo || {};

    if (initialPatientInfo.name?.key !== currentPatientInfo.name?.key) {
      addAuditData(
        "patientInfo",
        "name",
        initialPatientInfo.name?.key,
        currentPatientInfo.name?.key
      );
    }

    if (initialPatientInfo.gender?.key !== currentPatientInfo.gender?.key) {
      addAuditData(
        "patientInfo",
        "gender",
        initialPatientInfo.gender?.key,
        currentPatientInfo.gender?.key
      );
    }

    if (initialPatientInfo.id?.key !== currentPatientInfo.id?.key) {
      addAuditData(
        "patientInfo",
        "id",
        initialPatientInfo.id?.key,
        currentPatientInfo.id?.key
      );
    }

    if (initialPatientInfo.dob?.key !== currentPatientInfo.dob?.key) {
      addAuditData(
        "patientInfo",
        "dob",
        initialPatientInfo.dob?.key,
        currentPatientInfo.dob?.key
      );
    }

    if (initialPatientInfo.mbi?.key !== currentPatientInfo.mbi?.key) {
      addAuditData(
        "patientInfo",
        "mbi",
        initialPatientInfo.mbi?.key,
        currentPatientInfo.mbi?.key
      );
    }

    const initialProviderInfo = initialManualReviewData?.providerInfo || {};
    const currentProviderInfo = manualReviewData?.providerInfo || {};

    if (initialProviderInfo.name?.key !== currentProviderInfo.name?.key) {
      addAuditData(
        "providerInfo",
        "name",
        initialProviderInfo.name?.key,
        currentProviderInfo.name?.key
      );
    }

    if (
      initialProviderInfo.dateOfService?.key !==
      currentProviderInfo.dateOfService?.key
    ) {
      addAuditData(
        "providerInfo",
        "dateOfService",
        initialProviderInfo.dateOfService?.key,
        currentProviderInfo.dateOfService?.key
      );
    }

    if (initialProviderInfo.office?.key !== currentProviderInfo.office?.key) {
      addAuditData(
        "providerInfo",
        "office",
        initialProviderInfo.office?.key,
        currentProviderInfo.office?.key
      );
    }

    const buildSectionPayload = (initialData, currentData, section) => {
      currentData.forEach((item, index) => {
        const initialItem = initialData[index] || {};
        const sectionData = {};

        if (initialItem.icdCode?.key !== item.icdCode?.key) {
          sectionData.icdCode = {
            value: item.icdCode.key,
            audit: {
              oldValue: initialItem.icdCode?.key || "",
              newValue: item.icdCode.key,
            },
          };
        }

        if (
          section === "recaptures" &&
          initialItem.dateOfService?.key !== item.dateOfService?.key
        ) {
          sectionData.dateOfService = {
            value: item.dateOfService.key,
            audit: {
              oldValue: initialItem.dateOfService?.key || "",
              newValue: item.dateOfService.key,
            },
          };
        }

        if (
          section === "suspects" &&
          initialItem.sourceDate?.key !== item.sourceDate?.key
        ) {
          sectionData.sourceDate = {
            value: item.sourceDate.key,
            audit: {
              oldValue: initialItem.sourceDate?.key || "",
              newValue: item.sourceDate.key,
            },
          };
        }

        if (initialItem.documentation?.key !== item.documentation?.key) {
          sectionData.documentation = {
            value: item.documentation.key,
            audit: {
              oldValue: initialItem.documentation?.key || "",
              newValue: item.documentation.key,
            },
          };
        }

        if (section === "qualityMeasure") {
          if (initialItem.measure?.key !== item.measure?.key) {
            sectionData.measure = {
              value: item.measure.key,
              audit: {
                oldValue: initialItem.measure?.key || "",
                newValue: item.measure.key,
              },
            };
          }

          if (initialItem.recommendation?.key !== item.recommendation?.key) {
            sectionData.recommendation = {
              value: item.recommendation.key,
              audit: {
                oldValue: initialItem.recommendation?.key || "",
                newValue: item.recommendation.key,
              },
            };
          }
        }

        if (item.status) {
          let initialStatus = null;
          let currentStatus = null;

          ["A", "D", "N/A"].forEach((status) => {
            if (initialItem.status?.[status]?.key === true) {
              initialStatus = status;
            }
            if (item.status?.[status]?.key === true) {
              currentStatus = status;
            }
          });

          if (initialStatus !== currentStatus) {
            sectionData.status = {
              value: currentStatus,
              audit: {
                oldValue: initialStatus || "",
                newValue: currentStatus || "",
              },
            };
          }
        }

        if (Object.keys(sectionData).length > 0) {
          sectionData.rowId = index;
          payload[section].push(sectionData);
        }
      });
    };

    buildSectionPayload(
      initialManualReviewData.recaptures,
      manualReviewData.recaptures,
      "recaptures"
    );
    buildSectionPayload(
      initialManualReviewData.suspects,
      manualReviewData.suspects,
      "suspects"
    );
    buildSectionPayload(
      initialManualReviewData.qualityMeasure,
      manualReviewData.qualityMeasure,
      "qualityMeasure"
    );

    if (Object.keys(payload.patientInfo).length === 0)
      delete payload.patientInfo;
    if (Object.keys(payload.providerInfo).length === 0)
      delete payload.providerInfo;
    if (payload.recaptures.length === 0) delete payload.recaptures;
    if (payload.suspects.length === 0) delete payload.suspects;
    if (payload.qualityMeasure.length === 0) delete payload.qualityMeasure;

    console.log("Payload:", payload); // Debugging line to check payload

    try {
      const response = await patchData(`/documents/${id}/approve`, payload);
      if (response.status === 200) {
        toast.success("Document approved successfully");
        navigate("/pvp/manual_review_list");
      } else {
        toast.error("Failed to approve the document");
      }
    } catch (error) {
      console.error("Error approving document:", error);
      toast.error("Error approving document");
    } finally {
      setIsLoading(false); // Set loading to false once API call is finished
    }
  };

  const getInputStyle = (confidenceLevel) => {
    return confidenceLevel < 85 ? { backgroundColor: "#ffeae3" } : {};
  };

  const toggleEditRow = (section, index) => {
    setEditableRows((prevRows) => ({
      ...prevRows,
      [`${section}-${index}`]: !prevRows[`${section}-${index}`],
    }));
  };

  const renderRecaptures = () => {
    return (
      <>
        <thead>
          <tr>
            <th>ICD-10-CM & Description</th>
            <th>Prior Claims Date of Service</th>
            <th>Supporting Documentation</th>
            <th>A</th>
            <th>D</th>
            <th>N/A</th>
            <th style={{ width: "5%" }}>Edit</th>
          </tr>
        </thead>
        <tbody>
          {manualReviewData.recaptures.map((code, index) => (
            <tr key={index} className={index === 0 ? "hide-row" : ""}>
              <td style={getInputStyle(code.icdCode.confidenceLevel)}>
                {editableRows[`recaptures-${index}`] ? (
                  <input
                    type="text"
                    value={code.icdCode.key}
                    className="manual-review-input"
                    onChange={(e) =>
                      handleRowInputChange(e, "recaptures", index, "icdCode")
                    }
                  />
                ) : (
                  code.icdCode.key
                )}
              </td>
              <td style={getInputStyle(code.dateOfService.confidenceLevel)}>
                {editableRows[`recaptures-${index}`] ? (
                  <input
                    type="text"
                    value={code.dateOfService.key}
                    className="manual-review-input"
                    onChange={(e) =>
                      handleRowInputChange(
                        e,
                        "recaptures",
                        index,
                        "dateOfService"
                      )
                    }
                  />
                ) : (
                  code.dateOfService.key
                )}
              </td>
              <td style={getInputStyle(code.documentation.confidenceLevel)}>
                {editableRows[`recaptures-${index}`] ? (
                  <textarea
                    value={code.documentation.key}
                    className="manual-review-input"
                    onChange={(e) =>
                      handleRowInputChange(
                        e,
                        "recaptures",
                        index,
                        "documentation"
                      )
                    }
                  />
                ) : (
                  code.documentation.key
                )}
              </td>
              <td>
                <Form.Check
                  type="checkbox"
                  checked={code.status.A.key}
                  disabled={!editableRows[`recaptures-${index}`]}
                  onChange={(e) =>
                    handleCheckboxChange(e, "recaptures", index, "A")
                  }
                />
              </td>
              <td>
                <Form.Check
                  type="checkbox"
                  checked={code.status.D.key}
                  disabled={!editableRows[`recaptures-${index}`]}
                  onChange={(e) =>
                    handleCheckboxChange(e, "recaptures", index, "D")
                  }
                />
              </td>
              <td>
                <Form.Check
                  type="checkbox"
                  checked={code.status["N/A"].key}
                  disabled={!editableRows[`recaptures-${index}`]}
                  onChange={(e) =>
                    handleCheckboxChange(e, "recaptures", index, "N/A")
                  }
                />
              </td>
              <td>
                <Button className="pvp-form-edit-to-review">
                  <FaRegCheckCircle className="" />
                </Button>
                <Button className="pvp-form-edit-success">
                  <FaRegCheckCircle className="" />
                </Button>
                <Button
                  className="pvp-form-edit-button"
                  onClick={() => toggleEditRow("recaptures", index)}
                >
                  <FiEdit2 className="pvp-form-edit-icon" />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </>
    );
  };

  const renderSuspects = () => {
    return (
      <>
        <thead>
          <tr>
            <th>ICD-10-CM & Description</th>
            <th>Source Date</th>
            <th>Supporting Documentation</th>
            <th>A</th>
            <th>D</th>
            <th>N/A</th>
            <th style={{ width: "5%" }}>Edit</th>
          </tr>
        </thead>
        <tbody>
          {manualReviewData.suspects.map((condition, index) => (
            <tr key={index} className={index === 0 ? "hide-row" : ""}>
              <td style={getInputStyle(condition.icdCode.confidenceLevel)}>
                {editableRows[`suspects-${index}`] ? (
                  <input
                    type="text"
                    value={condition.icdCode.key}
                    className="manual-review-input"
                    onChange={(e) =>
                      handleRowInputChange(e, "suspects", index, "icdCode")
                    }
                  />
                ) : (
                  condition.icdCode.key
                )}
              </td>
              <td style={getInputStyle(condition.sourceDate.confidenceLevel)}>
                {editableRows[`suspects-${index}`] ? (
                  <input
                    type="text"
                    value={condition.sourceDate.key}
                    className="manual-review-input"
                    onChange={(e) =>
                      handleRowInputChange(e, "suspects", index, "sourceDate")
                    }
                  />
                ) : (
                  condition.sourceDate.key
                )}
              </td>
              <td
                style={getInputStyle(condition.documentation.confidenceLevel)}
              >
                {editableRows[`suspects-${index}`] ? (
                  <textarea
                    value={condition.documentation.key}
                    className="manual-review-input"
                    onChange={(e) =>
                      handleRowInputChange(
                        e,
                        "suspects",
                        index,
                        "documentation"
                      )
                    }
                  />
                ) : (
                  condition.documentation.key
                )}
              </td>
              <td>
                <Form.Check
                  type="checkbox"
                  checked={condition.status.A.key}
                  disabled={!editableRows[`suspects-${index}`]}
                  onChange={(e) =>
                    handleCheckboxChange(e, "suspects", index, "A")
                  }
                />
              </td>
              <td>
                <Form.Check
                  type="checkbox"
                  checked={condition.status.D.key}
                  disabled={!editableRows[`suspects-${index}`]}
                  onChange={(e) =>
                    handleCheckboxChange(e, "suspects", index, "D")
                  }
                />
              </td>
              <td>
                <Form.Check
                  type="checkbox"
                  checked={condition.status["N/A"].key}
                  disabled={!editableRows[`suspects-${index}`]}
                  onChange={(e) =>
                    handleCheckboxChange(e, "suspects", index, "N/A")
                  }
                />
              </td>
              <td>
                <Button className="pvp-form-edit-to-review">
                  <FaRegCheckCircle className="" />
                </Button>
                <Button className="pvp-form-edit-success">
                  <FaRegCheckCircle className="" />
                </Button>
                <Button
                  className="pvp-form-edit-button"
                  onClick={() => toggleEditRow("suspects", index)}
                >
                  <FiEdit2 className="pvp-form-edit-icon" />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </>
    );
  };

  const renderQualityMeasure = () => {
    return (
      <>
        <thead>
          <tr>
            <th>Measure</th>
            <th>Recommendation</th>
            <th>A</th>
            <th>D</th>
            <th>N/A</th>
            <th style={{ width: "5%" }}>Edit</th>
          </tr>
        </thead>
        <tbody>
          {manualReviewData.qualityMeasure.map((alert, index) => (
            <tr key={index} className={index === 0 ? "hide-row" : ""}>
              <td style={getInputStyle(alert.measure.confidenceLevel)}>
                {editableRows[`qualityMeasure-${index}`] ? (
                  <input
                    type="text"
                    value={alert.measure.key}
                    className="manual-review-input"
                    onChange={(e) =>
                      handleRowInputChange(
                        e,
                        "qualityMeasure",
                        index,
                        "measure"
                      )
                    }
                  />
                ) : (
                  alert.measure.key
                )}
              </td>
              <td style={getInputStyle(alert.recommendation.confidenceLevel)}>
                {editableRows[`qualityMeasure-${index}`] ? (
                  <textarea
                    value={alert.recommendation.key}
                    className="manual-review-input"
                    onChange={(e) =>
                      handleRowInputChange(
                        e,
                        "qualityMeasure",
                        index,
                        "recommendation"
                      )
                    }
                  />
                ) : (
                  alert.recommendation.key
                )}
              </td>
              <td>
                <Form.Check
                  type="checkbox"
                  checked={alert.status.A.key}
                  disabled={!editableRows[`qualityMeasure-${index}`]}
                  onChange={(e) =>
                    handleCheckboxChange(e, "qualityMeasure", index, "A")
                  }
                />
              </td>
              <td>
                <Form.Check
                  type="checkbox"
                  checked={alert.status.D.key}
                  disabled={!editableRows[`qualityMeasure-${index}`]}
                  onChange={(e) =>
                    handleCheckboxChange(e, "qualityMeasure", index, "D")
                  }
                />
              </td>
              <td>
                <Form.Check
                  type="checkbox"
                  checked={alert.status["N/A"].key}
                  disabled={!editableRows[`qualityMeasure-${index}`]}
                  onChange={(e) =>
                    handleCheckboxChange(e, "qualityMeasure", index, "N/A")
                  }
                />
              </td>
              <td>
                <Button className="pvp-form-edit-to-review">
                  <FaRegCheckCircle className="" />
                </Button>
                <Button className="pvp-form-edit-success">
                  <FaRegCheckCircle className="" />
                </Button>
                <Button
                  className="pvp-form-edit-button"
                  onClick={() => toggleEditRow("qualityMeasure", index)}
                >
                  <FiEdit2 className="pvp-form-edit-icon" />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </>
    );
  };

  return (
    <div className="manual-review-view">
      <div className="back-button-container">
        <Breadcrumb className="mt-3 ms-2">
          <Breadcrumb.Item>
            <Link className="text-dark link" to="/pvp">
              PVP
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link className="text-dark link" to="/pvp/manual_review_list">
              Inbound to Honest
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Review </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="review-content">
        <div className="pdf-viewer">
          {rawS3URL ? (
            <iframe src={rawS3URL} title="Document"></iframe>
          ) : (
            <Loader />
          )}
        </div>
        <div className="form-section pt-0">
          <div className="pvp-form">
            <Container className="pvp-patient-container">
              <Row className="pvp-form-row mb-3">
                <Col md={6} className="mt-2">
                  <Row>
                    <Col md={4}>
                      <h5 className="pvp-unique-id-title">Unique ID</h5>
                    </Col>
                    <Col md={5} className="pvp-unique-id-value">
                      {uniqueId}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="pvp-form-row mb-3">
                <Col md={6}>
                  <Row className="align-middle">
                    <Col md={3} className="pe-0">
                      <label className="pvp-form-label-padding">
                        Patient Name
                      </label>
                    </Col>
                    <Col md={9} className="ps-0">
                      <input
                        type="text"
                        className="pvp-form-control manual-review-input"
                        name="patientName"
                        value={formValues.patientName}
                        onChange={handleInputChange}
                        style={getInputStyle(
                          manualReviewData?.patientInfo?.name?.confidenceLevel
                        )}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md={6}>
                  <Row className="align-middle">
                    <Col md={4} className="pe-0">
                      <label className="pvp-form-label-padding">Gender</label>
                    </Col>
                    <Col md={8} className="ps-0">
                      <input
                        type="text"
                        className="pvp-form-control manual-review-input"
                        name="gender"
                        value={formValues.gender}
                        onChange={handleInputChange}
                        style={getInputStyle(
                          manualReviewData?.patientInfo?.gender?.confidenceLevel
                        )}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="pvp-form-row mb-3">
                <Col md={6}>
                  <Row className="align-middle">
                    <Col md={3} className="pe-0">
                      <label className="pvp-form-label-padding">
                        Patient ID
                      </label>
                    </Col>
                    <Col md={9} className="ps-0">
                      <input
                        type="text"
                        className="pvp-form-control manual-review-input"
                        name="patientId"
                        value={formValues.patientId}
                        onChange={handleInputChange}
                        style={getInputStyle(
                          manualReviewData?.patientInfo?.id?.confidenceLevel
                        )}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md={6}>
                  <Row className="align-middle">
                    <Col md={4} className="pe-0">
                      <label className="pvp-form-label-padding">
                        Provider Name
                      </label>
                    </Col>
                    <Col md={8} className="ps-0">
                      <input
                        type="text"
                        className="pvp-form-control manual-review-input"
                        name="providerName"
                        value={formValues.providerName}
                        onChange={handleInputChange}
                        style={getInputStyle(
                          manualReviewData?.providerInfo?.name?.confidenceLevel
                        )}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="pvp-form-row mb-3">
                <Col md={6}>
                  <Row className="align-middle">
                    <Col md={3} className="pe-0">
                      <label className="pvp-form-label-padding">
                        Patient DOB
                      </label>
                    </Col>
                    <Col md={9} className="ps-0">
                      <input
                        type="text"
                        className="pvp-form-control manual-review-input"
                        name="dob"
                        value={formValues.dob}
                        onChange={handleInputChange}
                        style={getInputStyle(
                          manualReviewData?.patientInfo?.dob?.confidenceLevel
                        )}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md={6}>
                  <Row className="align-middle">
                    <Col md={4} className="pe-0">
                      <label className="pvp-form-label-padding">
                        Office/IPA
                      </label>
                    </Col>
                    <Col md={8} className="ps-0">
                      <input
                        type="text"
                        className="pvp-form-control manual-review-input"
                        name="office"
                        value={formValues.office}
                        onChange={handleInputChange}
                        style={getInputStyle(
                          manualReviewData?.providerInfo?.office
                            ?.confidenceLevel
                        )}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="pvp-form-row mb-3">
                <Col md={6}>
                  <Row className="align-middle">
                    <Col md={3} className="pe-0">
                      <label className="pvp-form-label-padding">
                        Patient MBI
                      </label>
                    </Col>
                    <Col md={9} className="ps-0">
                      <input
                        type="text"
                        className="pvp-form-control manual-review-input"
                        name="mbi"
                        value={formValues.mbi}
                        onChange={handleInputChange}
                        style={getInputStyle(
                          manualReviewData?.patientInfo?.mbi?.confidenceLevel
                        )}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md={6}>
                  <Row className="align-middle">
                    <Col md={4} className="pe-0">
                      <label className="pvp-form-label-padding">
                        Date of Service
                      </label>
                    </Col>
                    <Col md={8} className="ps-0">
                      <input
                        type="text"
                        className="pvp-form-control manual-review-input"
                        name="dateOfService"
                        value={formValues.dateOfService}
                        onChange={handleInputChange}
                        style={getInputStyle(
                          manualReviewData?.providerInfo?.dateOfService
                            ?.confidenceLevel
                        )}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
            <div className="pvp-sub-section">
              <h5 className="pt-3 ps-3">Recaptures</h5>
              <Table className="manual-review-form-table tbl-align-middle">
                {manualReviewData && renderRecaptures()}
              </Table>
              <h5 className="pt-3 ps-3">Suspects</h5>
              <Table className="tbl-align-middle">{manualReviewData && renderSuspects()}</Table>
              <h5 className="pt-3 ps-3">Quality Measure</h5>
              <Table className="tbl-align-middle pb-0 mb-0">{manualReviewData && renderQualityMeasure()}</Table>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end mt-3">
        <Button className="primary-btn" onClick={handleApproveClick} disabled={isLoading}>
          {isLoading ? (
            <Spinner animation="border" size="sm" className="me-2" />
          ) : (
            <FaRegCheckCircle className="me-1" />
          )}
          Approve
        </Button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ManualReviewViewComponent;
