import React, { useState, useEffect } from "react";
import {
  Container,
  Table,
  Row,
  Col,
  InputGroup,
  FormControl,
  Pagination,
  Breadcrumb,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import { MdEdit } from "react-icons/md";
import { IoSearchSharp } from "react-icons/io5";
import { Navigate, Link } from "react-router-dom";
import Loader from "../../ui/Layout/Loader";
import { fetchData, patchData } from "../../api/apiService";
import usePermissions from "../../hooks/usePermissions";
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ApplicationConfiguration = () => {
  const [configurations, setConfigurations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [totalItems, setTotalItems] = useState(0);
  const [editItem, setEditItem] = useState(null);
  const [sortState, setSortState] = useState({
    name: "asc",
    value: "none",
  });

  const { permissions, permissionsLoaded } = usePermissions();

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  useEffect(() => {
    const fetchConfigurations = async () => {
      setLoading(true);
      try {
        const response = await fetchData("/application-configuration?key=all");
        setConfigurations(response.application_configurations);
        setTotalItems(response.application_configurations.length);
      } catch (error) {
        console.error("Error fetching configurations:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchConfigurations();
  }, []);

  const sortedConfigurations = configurations.sort((a, b) => {
    if (sortState.name !== "none") {
      const comparison = a.name.localeCompare(b.name);
      return sortState.name === "asc" ? comparison : -comparison;
    }
    return 0;
  });

  const filteredConfigurations = sortedConfigurations.filter((config) =>
    config.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredConfigurations.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleEdit = (item) => {
    setEditItem(item);
    setShowModal(true);
  };

  const handleSave = async () => {
    if (!editItem) return;
  
    setUpdating(true);
    try {
      await patchData(`/application-configuration/${editItem.id}`, {
        name: editItem.name,
        value: editItem.value,
      });
  
      setConfigurations((prevConfigurations) =>
        prevConfigurations.map((config) =>
          config.id === editItem.id
            ? { ...config, value: editItem.value }
            : config
        )
      );
  
      setEditItem(null);
      handleClose();
  
      toast.success("Configuration updated successfully!", {
        position: "top-right",
        autoClose: 3000,
      });
    } catch (error) {
      const errorMessage = error?.message || "Failed to update configuration.";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 3000,
      });
    } finally {
      setUpdating(false);
    }
  };
  

  const handleSort = (field) => {
    setSortState((prevState) => {
      const currentOrder = prevState[field];
      let newOrder = "none";

      if (currentOrder === "none") {
        newOrder = "asc";
      } else if (currentOrder === "asc") {
        newOrder = "desc";
      }

      return {
        ...prevState,
        [field]: newOrder,
      };
    });
  };

  const renderSortIcon = (field) => {
    const sortOrder = sortState[field];
    if (sortOrder === "asc") return <FaSortUp />;
    if (sortOrder === "desc") return <FaSortDown />;
    return <FaSort />;
  };

  if (!permissionsLoaded || loading) {
    return <Loader />;
  }

  const totalPages = Math.ceil(filteredConfigurations.length / itemsPerPage);

  return (
    <div>
      {permissions?.includes("administration_app_configuration") ? (
        <Container className="border border-light rounded px-5 mt-2">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link className="text-dark link" to="/administration">
                Administration
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Application Configuration</Breadcrumb.Item>
          </Breadcrumb>
          <Row className="mt-4 mb-2 justify-content-end">
            <Col md={4}>
              <InputGroup size="sm">
                <FormControl
                  placeholder="Search by Name"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <InputGroup.Text style={{ cursor: "pointer" }}>
                  <IoSearchSharp />
                </InputGroup.Text>
              </InputGroup>
            </Col>
          </Row>
          <Row className="px-2">
            <Table striped bordered hover className="pvp-list-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th
                    onClick={() => handleSort("name")}
                    style={{ cursor: "pointer" }}
                  >
                    Name {renderSortIcon("name")}
                  </th>
                  <th
                    onClick={() => handleSort("value")}
                    style={{ cursor: "pointer" }}
                  >
                    Value {renderSortIcon("value")}
                  </th>
                  <th>Edit</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.length > 0 ? (
                  currentItems.map((config, index) => (
                    <tr key={config.id}>
                      <td>{indexOfFirstItem + index + 1}</td>
                      <td>{config.name}</td>
                      <td>{config.value}</td>
                      <td>
                        <Button
                          className="btn btn-secondary"
                          onClick={() => handleEdit(config)}
                        >
                          <MdEdit />
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" className="text-center">
                      No data to display
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Row>
          <div className="d-flex justify-content-between align-items-center">
            <div className="font-size-14">
              Page {currentPage} of {totalPages}
            </div>
            <Pagination className="d-grid p-0 d-md-flex justify-content-md-end">
              <Pagination.First
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1}
              />
              <Pagination.Prev
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              />
              {(() => {
                const maxPageNumbersToShow = 5;
                const halfPageNumbersToShow = Math.floor(
                  maxPageNumbersToShow / 2
                );

                let startPage = Math.max(
                  currentPage - halfPageNumbersToShow,
                  1
                );
                let endPage = Math.min(
                  startPage + maxPageNumbersToShow - 1,
                  totalPages
                );

                if (endPage - startPage + 1 < maxPageNumbersToShow) {
                  startPage = Math.max(endPage - maxPageNumbersToShow + 1, 1);
                }

                const pages = [];
                for (let i = startPage; i <= endPage; i++) {
                  pages.push(
                    <Pagination.Item
                      key={i}
                      active={i === currentPage}
                      onClick={() => handlePageChange(i)}
                    >
                      {i}
                    </Pagination.Item>
                  );
                }

                return (
                  <>
                    {startPage > 1 && <Pagination.Ellipsis disabled />}
                    {pages}
                    {endPage < totalPages && <Pagination.Ellipsis disabled />}
                  </>
                );
              })()}
              <Pagination.Next
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              />
              <Pagination.Last
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages}
              />
            </Pagination>
          </div>

          {/* Edit Modal */}
          <Modal show={showModal} onHide={handleClose} className="modal-lg">
            <Modal.Header closeButton>
              <Modal.Title>Edit Configuration</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Row>
                  <Col xs={3}>
                    <Form.Group controlId="configName">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="configName"
                        value={editItem?.name || ""}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={9}>
                    <Form.Group controlId="configValue">
                      <Form.Label>Value</Form.Label>
                      <Form.Control
                        type="text"
                        name="configValue"
                        value={editItem?.value || ""}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          // Apply the 30 character limit only for cron_ keys
                          if (
                            editItem?.name &&
                            editItem.name.startsWith("cron_") &&
                            inputValue.length > 30
                          ) {
                            return; // Prevent input from exceeding 30 characters
                          }
                          setEditItem({
                            ...editItem,
                            value: inputValue,
                          });
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-4">
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    onClick={handleSave}
                    disabled={updating}
                  >
                    {updating ? "Saving..." : "Save"}
                  </Button>
                </div>
              </Form>
            </Modal.Body>
          </Modal>
          <ToastContainer />
        </Container>
      ) : (
        <Navigate to="/401" replace />
      )}
    </div>
  );
};

export default ApplicationConfiguration;
